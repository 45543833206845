<template>
  <div>
    <loading :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="true"/>

    <h1 class="text-3xl">MQTT Action Monitor Logs</h1>
    <div class="container mx-auto mt-6 px-20">
      <p v-if="errors.length" class="error-block">
        <b>Please correct the following error(s):</b>
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </p>
      <form @submit.prevent="handleSubmit">
          <div class="mb-3 flex"><label class="mx-3" for="email">Email</label> <input class="border flex-grow pl-1" type="text" id="email" v-model="email" size="100"></div>
          <div class="mb-3 flex"><label class="mx-3" for="client_id">ThingName, Macaddress</label> <input class="border flex-grow pl-1" type="text" id="client_id" v-model="clientId" size="100" placeholder="ap-northeast-1:3cca821a-e93f-4dde-b983-626bd16a7b8c_66428598619501"></div>
          <div class="mb-3 flex"><label class="mx-3" for="start_time">Start Time( UTC+0 )</label> <flat-pickr v-model="startTime" :config="flatPickrConfig"></flat-pickr></div>
          <div class="mb-3 flex"><label class="mx-3" for="end_time">End Tim( UTC+0 )</label> <flat-pickr v-model="endTime" :config="flatPickrConfig"></flat-pickr></div>
          <div class="mb-3 flex"><label class="mx-3" for="limit">Limit</label> <input class="border flex-grow pl-1" type="text" id="limit" v-model="limit" size="15"></div>
          <div class="flex justify-center">
            <button class="bg-gray-500 px-4 py-2 rounded-lg text-white mx-3" type="submit">Search</button>
            <button class="bg-gray-400 px-4 py-2 rounded-lg text-white" @click.prevent="csvExport" v-if="iotLogs.length > 0">Export CSV</button>
          </div>
      </form>
      <div class="mt-10" v-if="iotLogs.length > 0">
        <ol class="logs">
          <li class="relative" v-for="item,index in iotLogs" v-bind:key="item.timestamp">
            <pre>{{ item }}</pre>
            <div class="absolute top-0 left-0 -translate-x-full w-10 h-10  bg-[#FFAC55] text-black flex items-center justify-center">{{index+1}}</div>
          </li>
        </ol>
      </div>
      <div class="mt-10" v-else>No Data</div>
      </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import https from '@/helpers/https'
import { sleep } from '@/helpers'


export default {
  data() {
    return {
      errors: [],
      isLoading: false,
      promise: null,
      iotLogs: [],
      email: "",
      clientId: "",
      startTime: null,
      endTime: null,
      limit: 50,
      flatPickrConfig: {
          enableTime: true,
          time_24hr: true,
          altFormat: 'Z',
          altInput: true,
          dateFormat: 'U',
          minDate: new Date().fp_incr(-15),
      },
    }
  },
  components: {
    Loading,
    flatPickr
  },
  methods: {
    csvExport() {
      let csvContent = "data:text/csv;charset=utf-8,";
      const header = Object.keys(this.iotLogs[0]);
      csvContent += [
        header.join(';'),
        ...this.iotLogs.map(row => header.map(fieldName => JSON.stringify(row[fieldName])).join(';'))
      ].join('\r\n').replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      let names = [
        this.email,
        this.clientId,
        this.startTime,
        this.endTime
      ].filter(x => x)
      link.setAttribute("download", `mqtt__monitor__logs__${names.join("__")}.csv`);
      link.click();
    },
    async handleSubmit() {
      this.errors = [];

      if (!this.startTime) {
        this.errors.push('Start Time required.');
      }
      if (!this.endTime) {
        this.errors.push('End Time required.');
      }
      if (this.startTime > this.endTime) {
        this.errors.push('End Time should not be before Start Time.');
      }

      if (!this.email && !this.clientId) {
        this.errors.push('Email or ClientId required.');
      }

      if (this.errors.length > 0) {
        return;
      }
      this.isLoading = true;
      try {
        const instance = await https.axiosInstance();
        const path = `/logs/iot?client_id=${encodeURIComponent(this.clientId)}&email=${encodeURIComponent(this.email)}&start_time=${encodeURIComponent(this.startTime)}&end_time=${encodeURIComponent(this.endTime)}&limit=${encodeURIComponent(this.limit)}`;
        const query_response = await instance.get(path);

        const queryId = query_response.data.QueryId;

        await sleep(10000);
        const url_path = `/logs/iot/results?query_id=${encodeURIComponent(queryId)}`;
        const response = await https.pollingGet(url_path);
        if (response && response.data) {
          this.iotLogs = response.data;
        } else {
          alert('exceed retry count');
        }
      } catch (error) {
        let responseContent = '';
        if (error.response !== undefined) {
          responseContent = "\n" + JSON.stringify(error.response.data);
        }
        alert("Error: " + error.message + responseContent);
      }
      this.isLoading = false;
    },
    onCancel() {
      this.isLoading = false;
    },
  },
}
</script>

<style>
.logs {
  text-align: left;
}
.logs > li {
  border: 1px #FFAC55 solid;
  margin: 1px 0;
}
.logs > li > pre {
  margin: 0px;
}
.form-control.input {
  border: 1px solid #eeeeee;
  flex-grow: 1;
}
.error-block {
  color: red;
}
</style>